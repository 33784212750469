














import { Component, Vue } from 'vue-property-decorator'
import VueRecaptcha from 'vue-recaptcha'

type IVueRecaptcha = {
  reset: () => void
}

@Component({
  components: {
    VueRecaptcha
  }
})
export default class Recaptcha extends Vue {
  sitekey = '6LeUlE8pAAAAACE1I15C2A7Z7tNzfo7sfafeyOKd'

  mounted() {
    this.$root.$on('resetCaptcha', () => this.onCaptchaExpired())
  }

  beforeDestroy() {
    this.$root.$off('resetCaptcha', () => this.onCaptchaExpired())
  }

  onCaptchaVerified(recaptchaToken: string): void {
    this.$emit('getRecaptcha', recaptchaToken)
  }

  onCaptchaExpired() {
    (this.$refs.recaptcha as Vue & IVueRecaptcha).reset()
  }
}
